/* eslint no-underscore-dangle: 0 */
import { observable, makeObservable } from 'mobx'

class ChatStore {
  minimised = {}
  panel = 'contacts'
  to = {}

  constructor() {
    makeObservable(this, {
      minimised: observable,
      panel: observable,
      to: observable,
    })
  }
}

const chatStore = new ChatStore()

export default chatStore
